import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery";
import Turbolinks from "turbolinks"
import Parallax from "parallax-js"
import "stylesheets/application";
Turbolinks.start()


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function () {
  const scene = document.getElementById('scene');
  if (scene) {
    new Parallax(scene);
  }

  $("form").on("change", ".file-upload", function () {
    const $wrapper = $(this).parents(".file-wrapper");
    const $filename = $(this).val().replace(/.*(\/|\\)/, '');
    $wrapper.attr(
      "data-text",
      $filename
    );
    if ($filename.length) {
      return $wrapper.find(".form-item-description").addClass("d-none");
    } else {
      return $wrapper.find(".form-item-description").removeClass("d-none");
    }
  });

  const menuGate = document.querySelector(".menu-gate");
  if(menuGate){
    menuGate.addEventListener('click',(e)=>{
      document.querySelector('body').classList.toggle('disable-scroll');
      document.querySelector('.menu-background').classList.toggle('menu-show');
      document.querySelector('.menu').classList.toggle('menu-show');
    })
  }
  const menuBackground = document.querySelector(".menu-background");
  if(menuBackground){
    menuBackground.addEventListener('click', (e)=>{
      document.querySelector('body').classList.remove('disable-scroll');
      document.querySelector('.menu-background').classList.remove('menu-show');
      document.querySelector('.menu').classList.remove('menu-show');
    })
  }
});

document.addEventListener("turbolinks:before-cache", function () {
  $('.g-recaptcha').empty();
});
